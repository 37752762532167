.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

h1 {
  font-size: 28px;
  font-weight: 700;
  padding: 10px 0;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  padding: 10px 0;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0;
}

.link {
  text-decoration: underline;
  color: rgb(93, 143, 236);
}

.progress-container {
  padding: 40px 10px;
}

.input-container {
  border: 1px solid gray;
  border-radius: 5px;
  width: 80vw;
  max-width: 600px;
  display: flex;
  justify-items: center;
  margin: 0 auto;
}

input {
  width: 60%;
  border-radius: 5px;
  padding: 5px;
}

.button {
  width: 40%;
  background-color: rgb(54, 54, 54);
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
}

.category-container {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
}

.performance-container {
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  display: flex;
  justify-items: center;
  justify-content: center;
  margin: 0 auto;
}

.category-audits,
.page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.category-audits > div {
  border: 1px solid black;
  padding: 5px;
  width: 45vw;
  max-width: 600px;
}

.check-pass {
  background-color: mediumaquamarine;
}

.check-fail {
  background-color: palevioletred;
}

.page-container > div {
  border: 1px solid black;
  padding: 5px;
  width: 30vw;
  max-width: 600px;
}

@media screen and (max-width: 650px) {
  .category-audits > div {
    width: 90vw;
  }

  .page-container > div {
    width: 80vw;
  }
}
